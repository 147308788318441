.host {
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
}

.logo {
    width: 170px;
    height: 43px;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-right: -8px;
}

.userAccess {
    margin-right: 16px;
}

@media (max-width: 600px) {
    .host {
        align-items: center;
        flex-direction: column;
        padding: 12px 16px;
    }

    .buttons {
        margin-top: 5px;
        margin-right: 0;
    }

    .userAccess {
        margin-right: auto;
    }
}
