.row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-bottom: 10px;
}

.rowHead {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    composes: row;
    background-color: transparent;
    margin-bottom: 0;
    word-break: break-word;
}

@media (min-width: 600px) {
    .host {
        min-width: 930px;
    }

    .row {
        flex-direction: row;
    }

    .rowHead {
        flex-direction: row;
    }

    .cell {
        text-align: center;
        padding: 0 5px;
        box-sizing: border-box;

        &:nth-child(1) {
            min-width: 12%;
            max-width: 12%;
        }

        &:nth-child(2) {
            min-width: 16%;
            max-width: 16%;
        }

        &:nth-child(3) {
            min-width: 10%;
            max-width: 10%;
        }

        &:nth-child(4) {
            min-width: 14%;
            max-width: 14%;
        }

        &:nth-child(5) {
            min-width: 24%;
            max-width: 24%;
        }

        &:nth-child(6) {
            min-width: 24%;
            max-width: 24%;
        }
    }
}
