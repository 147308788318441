/* poppins-300 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src:
        local(''),
        url('./assets/fonts/poppins-v20-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/poppins-v20-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-300italic - latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src:
        local(''),
        url('./assets/fonts/poppins-v20-latin-300italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/poppins-v20-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src:
        local(''),
        url('./assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/poppins-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-italic - latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src:
        local(''),
        url('./assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/poppins-v20-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-500 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src:
        local(''),
        url('./assets/fonts/poppins-v20-latin-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/poppins-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src:
        local(''),
        url('./assets/fonts/poppins-v20-latin-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/poppins-v20-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* poppins-600italic - latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src:
        local(''),
        url('./assets/fonts/poppins-v20-latin-600italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/poppins-v20-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* oswald-300 - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src:
        local(''),
        url('./assets/fonts/oswald-v49-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/oswald-v49-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* oswald-regular - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src:
        local(''),
        url('./assets/fonts/oswald-v49-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/oswald-v49-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* oswald-700 - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    src:
        local(''),
        url('./assets/fonts/Oswald-Medium.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./assets/fonts/Oswald-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* hide arrows in number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}
