.host {
    position: relative;
}

.tooltip {
    position: absolute;
    padding: 0 6px;
    top: 2px;
    right: 4px;
}

.tooltipOut {
    top: -7px;
    right: -24px;
}

.asterisk {
    position: absolute;
    top: 9px;
    right: 6px;
    line-height: 10px;
}

.withTooltip .asterisk {
    right: 22px;
}
