.withSidebar {
    display: flex;
    width: 100vw;
    max-width: 1150px;
}

.contentBox {
    margin-right: 20px;
    flex-grow: 1;
}

.sidebarBox {
    min-width: 350px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebarBox > *:not(:first-child) {
    margin-top: 15px;
}

@media (max-width: 991px) {
    .withSidebar {
        flex-direction: column;
    }

    .contentBox {
        width: auto;
        margin-bottom: 15px;
        margin-right: 0;
    }

    .sidebarBox {
        min-width: auto;
        max-width: none;
    }
}
