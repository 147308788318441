.host {
    display: block;
}

.row {
    display: flex;
    padding-bottom: 10px;
}

.item {
    flex-grow: 1;
    min-width: 50%;
    max-width: 50%;
    box-sizing: border-box;

    &:nth-child(odd) {
        padding-right: 5px;
    }

    &:nth-child(even) {
        padding-left: 5px;
    }
}

.itemFullWidth {
    flex-grow: 1;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 10px;
    row-gap: 10px;
}

.gridCell {
    display: flex;
    align-items: center;
}

.salutation {
    align-self: center;
    margin-right: 10px;
}

.salutationMark {
    vertical-align: top;
}

.divider {
    margin: 10px 0 20px;
}

.bottomBox {
    margin-top: 20px;
}

.buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}

.termsInnerBox {
    display: flex;
    flex-direction: column;
}

.expansionsBox {
    margin: 15px 0;
}

.expansionBody {
    padding: 10px;
}

.sidebarBottomBox {
    display: none;
}

.editUserRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 900px) {
    .bottomBox {
        display: none;
    }

    .sidebarBottomBox {
        display: block;
    }
}

@media (max-width: 600px) {
    .row {
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .item {
        width: 100%;
        max-width: none;
        min-width: auto;
        padding-bottom: 10px;

        &:nth-child(odd) {
            padding-right: 0;
        }

        &:nth-child(even) {
            padding-left: 0;
        }
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        column-gap: 10px;
        row-gap: 10px;
    }
}
