.logoBox {
    flex-grow: 1;
    max-width: 420px;
    margin-right: 24px;
    padding-top: 7px;
    margin-left: -220px;
}

.formBox {
    flex-grow: 1;
    max-width: 450px;
    width: 100%;
}

.logo {
    display: block;
    width: 413px;
    height: 360px;
}

@media (max-width: 900px) {
    .box.twoTransfers {
        flex-direction: column;
        align-items: center;
    }

    .box.twoTransfers .formBox + .formBox {
        margin-left: 0;
        margin-top: 30px;
    }

    .box:not(.twoTransfers) .logoBox {
        display: none;
    }
}

@media (max-width: 1080px) {
    .box:not(.twoTransfers) .logoBox {
        margin-left: 0;
    }
}

@media (max-width: 1330px) {
    .box.twoTransfers .logoBox {
        display: none;
    }
}

@media (max-width: 1535px) {
    .box.twoTransfers .logoBox {
        margin-left: 0;
    }
}
